import './Paragraph.scss'

export interface ParagraphProps {
  texts: Array<string> | undefined
}

function Paragraph({ texts }: ParagraphProps): JSX.Element {
  return (
    <>
      {texts &&
        texts.map((text) => (
          <p className="paragraph" key={text}>
            {text}
          </p>
        ))}
    </>
  )
}

export default Paragraph
