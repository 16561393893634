export const navbar = [
  {
    path: 'sculpture',
    display: 'Sculpture',
    external: false,
  },
  {
    path: 'prints',
    display: 'Prints',
    external: false,
  },
  {
    path: 'drawing',
    display: 'Drawing+Painting',
    external: false,
  },
  {
    path: 'books',
    display: 'Books',
    external: false,
  },
  {
    path: 'pinatas',
    display: 'Piñatas',
    external: false,
  },
  {
    path: 'projects',
    display: 'Projects',
    external: false,
  },
  {
    path: 'about',
    display: 'About',
    external: false,
  },
  {
    path: 'store',
    display: 'Store',
    external: false,
  },
]
