import { NavLink } from 'react-router-dom'
import { navbar } from 'constants/nav'
import './Nav.scss'

function Nav(): JSX.Element {
  return (
    <nav className="nav">
      {navbar.map((nav) =>
        nav.external ? (
          <a href={nav.path} key={nav.path} className={'nav-button'}>
            {nav.display.toUpperCase()}
          </a>
        ) : (
          <NavLink
            to={`/${nav.path}`}
            className={({ isActive }) => (isActive ? 'nav-button nav-button-active' : 'nav-button')}
            key={nav.path}
          >
            {nav.display.toUpperCase()}
          </NavLink>
        ),
      )}
    </nav>
  )
}

export default Nav
