export const prints = [
  {
    imageSrc: require('assets/images/prints/me_and_rufus.jpg'),
    title: 'Me and Rufus',
    alt: 'Risograph of a portrait of the artist holding her beloved cat, Rufus',
    date: '2021',
    medium: 'Risograph',
    size: '9.625 x 7.25 in.',
    notes: 'Edition 30',
  },
  {
    imageSrc: require('assets/images/prints/kisses_tears.jpg'),
    title: 'Kisses, Tears',
    alt: 'Lithograph of a lipstick stain on a silk handkerchief',
    date: '2016',
    medium: 'Lithograph on silk charmeuse with hand-rolled hems, perfume, tears',
    size: '11.5 x 11.5 in.',
    notes: 'Edition 12',
  },
  {
    imageSrc: require('assets/images/prints/kisses_tears_avec_pepe.jpg'),
    title: 'Kisses, Tears',
    alt: 'Lithograph of a lipstick stain and Pepé le Pew on a silk handkerchief',
    date: '2016',
    medium: 'Lithograph on silk charmeuse with hand-rolled hems, perfume, tears',
    size: '11.5 x 11.5 in.',
    notes: 'Edition 7',
  },
  {
    imageSrc: require('assets/images/prints/prince_napoleon.jpg'),
    title: 'Prince Napoleon I',
    alt: 'Diptych of prints depicting Prince riding a buffalo and Napoleon atop a white kitten',
    date: '2009',
    medium: 'Watercolor monotype',
    size: '16.75 x 26 in.',
    notes: 'Collaboration with Rie Hasegawa',
  },
  {
    imageSrc: require('assets/images/prints/golden_fleece.jpg'),
    title: 'The Golden Fleece',
    alt: 'Lithograph of a drawing of chest hair dusted with gold mica for a shimmery effect',
    date: '2009',
    medium: 'Lithograph with gold mica',
    size: '19 x 16 in.',
    notes: 'Edition 8',
  },
  {
    imageSrc: require('assets/images/prints/xoxo_christian_bale.jpg'),
    title: 'XOXO Christian Bale',
    alt: 'Lithograph suite of fake Christian Bale signed photos',
    date: '2008',
    medium: 'Lithograph',
    size: '8 x 10 in. each',
    notes: 'Edition 7',
  },
  {
    imageSrc: require('assets/images/prints/buttholebeefcake.jpg'),
    title: 'BUTTHOLEBEEFCAKE',
    alt: 'Lithograph of a prom photo',
    date: '2009',
    medium: 'Lithograph with perforations',
    size: '6 x 15 in.',
    notes: 'Edition 8',
  },
]
