import { useState } from 'react'
import './CheckoutButton.scss'

const url = '/checkout'
export interface CheckoutButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripePromise: any
  priceId: string | undefined
  shippingId: string | undefined
}

function CheckoutButton({ stripePromise, priceId, shippingId }: CheckoutButtonProps): JSX.Element {
  const [disabled, setDisabled] = useState(false)

  const handleClick = async (event: React.MouseEvent) => {
    event.preventDefault()
    setDisabled(true)
    const stripe = await stripePromise
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        price_id: priceId,
        shipping_id: shippingId,
      }),
    })

    try {
      const session = await response.json()
      if (session.id) {
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        })

        if (result.error) {
          throw new Error('Network Error')
        }
      } else {
        throw new Error(session.error)
      }
    } catch (error) {
      alert(error.message)
      setDisabled(false)
    }
  }

  return (
    <button disabled={disabled} className="CheckoutButton" role="link" onClick={handleClick}>
      Buy It Now
    </button>
  )
}

export default CheckoutButton
