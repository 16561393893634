export const drawings = [
  {
    imageSrc: require('assets/images/drawing/lovely_pony.jpg'),
    title: 'Lovely Pony',
    alt: 'Pony wearing a red cardigan in a field by a lake and a mountain',
    date: '2013',
    medium: 'Oil pastel on canvas board',
    size: '9 x 12 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/tiger_and_arc_de_triomphe.jpg'),
    title: 'Tiger and Arc de Triomphe (after Christopher Wood)',
    alt: 'A tiger and leopard sit in front of the Arc de Triomphe',
    date: '2013',
    medium: 'Watercolor on paper',
    size: '9 x 11 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/body_pressure.jpg'),
    title: 'This May Become a Very Erotic Exercise',
    alt: 'Pizza stickers stuck in the shape of a slice of pizza on pink paper',
    date: '2011',
    medium:
      'Scratch and sniff stickers stuck in the shape of a pizza slice on the back of a Bruce Nauman “Body Pressure” poster',
    size: '25 x 16.5 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/mini_stallion_of_wassaic.jpg'),
    title: 'Mini Stallion of Wassaic',
    alt: 'Miniature stallion walks in a field',
    date: '2011',
    medium: 'Watercolor on paper',
    size: '5 x 7 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/the_first_duty_of_love.jpg'),
    title: 'The first duty of love is to listen.',
    alt: 'Cat with a pink bow and fuzzy red heart',
    date: '2013',
    medium: 'Oil pastel, faux fur, fabric, and rhinestones on canvas board',
    size: '8 x 10 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/hated_it.jpg'),
    title: 'HATED IT!',
    alt: 'Rainbow arc made from the words "HATED IT!"',
    date: '2011',
    medium: 'Enamel on cardboard',
    size: '72 x 144 x 0.25 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/drawing/leo_steinberg.jpg'),
    title: 'Leo Steinberg',
    alt: 'Portrait of a rosy-cheeked Leo Steinberg',
    date: '2008',
    medium: 'Oil on canvas',
    size: '38 x 22 in.',
    notes: '',
  },
]
