import './Video.scss'

export interface VideoProps {
  source: string
  title: string
  loop: boolean
  muted: boolean
  autoplay: boolean
  color?: string
}

function Video({
  source,
  title,
  loop = true,
  muted = true,
  autoplay = false,
  color = 'D8BFD8',
}: VideoProps): JSX.Element {
  return (
    <div className="video">
      <iframe
        src={`${source}?byline=0&portrait=0&title=0&responsive=1&loop=${loop}&muted=${muted}&autoplay=${autoplay}&color=${color}`}
        title={title}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )
}

export default Video
