import { useRef } from 'react'
import { ImageModel } from 'models/ImageModel'
import useObserver from 'utils/useObserver'
import placeholder from 'assets/common/garfield.gif'
import './LazyImage.scss'

export interface LazyImageProps {
  image: ImageModel
  showCaption: boolean
}

function LazyImage({ image, showCaption = true }: LazyImageProps): JSX.Element {
  const ref = useRef<HTMLImageElement | null>(null)
  const observed = useObserver(ref, { threshold: 0.9 })

  if (observed?.isIntersecting) {
    const img = observed.target as HTMLImageElement
    img.src = img.dataset.src as string
  }

  return (
    <figure className="lazy-image">
      <img ref={ref} src={placeholder} data-src={image.imageSrc} alt={image.alt} />
      {showCaption && (
        <figcaption>
          {`${image.title}`}
          {`\u2002\u2020\u2002${image.date}`}
          {image.medium && `\u2002\u2020\u2002${image.medium}`}
          {image.size && `\u2002\u2020\u2002${image.size}`}
          {image.notes && `\u2002\u2020\u2002${image.notes}`}
        </figcaption>
      )}
    </figure>
  )
}

export default LazyImage
