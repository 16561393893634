export const store = [
  {
    imageSrc: require('assets/images/sculpture/self_portrait_with_peonies.jpg'),
    title: 'Self Portrait with Peonies',
    alt: "Pleated cloth facemask with a print of the artist's face",
    date: '2020',
    medium: 'Inkjet print on fabric, elastic',
    size: '5.5 x 10 x 3 in.',
    description: ["Pleated cotton facemask with a print of the artist's face", '5.5 x 10 x 3 in.', 'Edition 8'],
    price: 'USD 500',
    priceId: 'price_1I5LcdKdikx0aOCUNMJkh20n',
    shippingId: 'shr_1IGzgEKdikx0aOCUAZHLlu80',
  },
  {
    imageSrc: require('assets/images/sculpture/console_dot_dog.gif'),
    title: 'Console Dog, Still a Good Boy',
    alt: 'White ceramic mug printed with black text that reads "console.dog(\'Still a good boy.\')"',
    date: '2021',
    medium: 'Stoneware, glaze',
    size: '4.5 x 5 x 3.5 in.',
    description: [
      'White stoneware mug for the developer and dog lover',
      'The text reads: console.dog("Still a good boy.")',
    ],
    price: 'USD 36',
  },
  {
    imageSrc: require('assets/images/books/firework.gif'),
    title: "Baby, you're a firework",
    alt: 'Flip book of an inflatable tube man dancing',
    date: '2020',
    medium: 'Laser print on paper, perfect bind',
    size: '2.5 x 3.75 x 0.25 in.',
    description: ['A flipbook to uplift you', 'Published by Jing Yu Fan Club, May 2020', 'Open Edition'],
    price: 'Free DIY PDF',
  },
]
