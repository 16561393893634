import { Outlet } from 'react-router-dom'
import { Header, Footer, Nav } from 'components'

function Layout(): JSX.Element {
  return (
    <main className="layout">
      <Header />
      <Nav />
      <Outlet />
      <Footer />
    </main>
  )
}

export default Layout
