import { Route, Routes } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Home, Gallery, Layout, Pinatas, Projects, NotFound, About, Store } from 'views'
import * as Sculpture from 'constants/sculpture'
import * as Books from 'constants/books'
import * as Prints from 'constants/prints'
import * as Drawing from 'constants/drawing'
import { hangInThereFlower } from 'assets/common/consoles'
import './App.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)

function App(): JSX.Element {
  console.log(`%c${hangInThereFlower}`, 'color: springgreen')
  return (
    <div className="App">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Layout />}>
          <Route path="/sculpture" element={<Gallery checklist={Sculpture.sculptures} />} />
          <Route path="/prints" element={<Gallery checklist={Prints.prints} />} />
          <Route path="/drawing" element={<Gallery checklist={Drawing.drawings} />} />
          <Route path="/books" element={<Gallery checklist={Books.books} />} />
          <Route path="/pinatas" element={<Pinatas />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/store" element={<Store stripePromise={stripePromise} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
