export const text = [
  'Custom portrait piñatas for your next birthday party, corporate event, wedding, divorce, &c — finest quality papier-mâché piñatas made in the image of any person and filled with treats and surprises of your choice. Popular picks include you, current and ex-lovers, beloved pets, and best friends. Custom piñatas are constructed with layers of medium weight acid free paper and PVA, and finished with artist grade acrylic paints and papers. These pieces can be lasting keepsakes long after the party is over. The price for an individual piñata starts at $600, not including the cost for favors or shipping. Please allow up to three weeks for the completion of your custom piñata.',
]

export const pinatas = [
  {
    imageSrc: require('assets/images/pinatas/jing.jpg'),
    title: 'The Artist',
    alt: 'Portrait of the artist as a piñata',
    date: '2010',
  },
  {
    imageSrc: require('assets/images/pinatas/halo.jpg'),
    title: 'Halo',
    alt: 'a portrait piñata of Halo, a dog',
    date: '2017',
  },
  {
    imageSrc: require('assets/images/pinatas/ali_mark.jpg'),
    title: 'Ali and Mark',
    alt: 'Portrait piñatas of Ali and Mark',
    date: '2016',
  },
  {
    imageSrc: require('assets/images/pinatas/nate.jpg'),
    title: 'Nate Charlow',
    alt: 'Portrait piñata of Nate Charlow',
    date: '2010',
  },
  {
    imageSrc: require('assets/images/pinatas/echo.jpg'),
    title: 'Echo',
    alt: 'Portrait piñata of Echo, a dog',
    date: '2017',
  },
  {
    imageSrc: require('assets/images/pinatas/kevin_jen.jpg'),
    title: 'Jen and Kevin',
    alt: 'Portrait piñatas of Kevin Smith and Jennifer Schwalbach',
    date: '2013',
  },
  {
    imageSrc: require('assets/images/pinatas/ny_weddings.jpg'),
    title: 'New York Weddings',
    alt: 'New York Weddings Best Custom Piñatas article clipping',
    date: '2015',
  },
  {
    imageSrc: require('assets/images/pinatas/brangie.jpg'),
    title: 'Brangie',
    alt: 'Portrait piñatas of Brangie',
    date: '2016',
  },
  {
    imageSrc: require('assets/images/pinatas/dylan.jpg'),
    title: 'Dylan',
    alt: 'Portrait piñata of Dylan',
    date: '2015',
  },
]

export const press = [
  {
    link: 'http://www.marthastewartweddings.com/612755/colorful-patterned-brooklyn-wedding-samm-blake#612386',
    publication: 'Martha Stewart Weddings, Real Weddings Spring 2017',
  },
  {
    link: 'http://nymag.com/weddings/planner/2015/winter/all-custom-everything/',
    publication: 'New York Magazine, Winter Weddings 2015',
  },
  {
    link: 'http://nymag.com/bestofny/kids/2013/party-entertainment/',
    publication: 'New York Magazine, Best of New York 2013',
  },
  {
    link: 'https://www.thehairpin.com/2013/01/the-personalized-party-pinata/',
    publication: 'The Hairpin',
  },
  {
    link: 'http://www.dailycandy.com/new-york/article/142178/Jing-Yu-Custom-Pinata',
    publication: 'Daily Candy',
  },
  {
    link: 'http://blog.urbanoutfitters.com/blog/jing_yus_pinatas',
    publication: 'Urban Outfitters',
  },
]
