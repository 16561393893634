import { Link } from 'react-router-dom'

import './Home.scss'

function Home(): JSX.Element {
  return (
    <main className="home">
      <video playsInline autoPlay muted loop>
        <source src={require('assets/common/prima.mp4')} type="video/mp4" />
      </video>
      <header className="home-header">
        <Link to="/sculpture">Jing Yu Fan Club</Link>
      </header>
      <p className="closed-caption">
        <Link to="/sculpture">[music builds to a crescendo]</Link>
      </p>
    </main>
  )
}

export default Home
