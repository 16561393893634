import './Footer.scss'

function Footer(): JSX.Element {
  function handleSubmit() {
    window.open('https://tinyletter.com/jingyufanclub', 'popupwindow', 'scrollbars=yes,width=800,height=600')
  }
  return (
    <footer className="footer">
      <div>
        &copy; <span className="year">{new Date().getFullYear()} Jing Yu</span>
      </div>
      <br />
      <form action="https://tinyletter.com/jingyufanclub" method="post" target="popupwindow" onSubmit={handleSubmit}>
        <input type="text" name="email" id="tlemail" placeholder="Email Address" aria-label="enter email address" />
        <input type="hidden" value="1" name="embed" />
        <button type="submit">Subscribe to Fan Fiction</button>
      </form>
    </footer>
  )
}

export default Footer
