import './Projects.scss'

function Projects(): JSX.Element {
  return (
    <section className="projects">
      <a href="https://not-a-rug-pull.xyz/">This is Not a Rug Pull</a>
      <a href="https://fan-fiction.xyz/">Books on the World Wide Web</a>
      <a href="https://duncecapsforcats.com">Dunce Caps for Cats</a>
      <a href="https://jingyufan.club/sounds-like-enjoy/">Sounds Like Enjoy</a>
    </section>
  )
}

export default Projects
