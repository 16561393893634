export const books = [
  {
    imageSrc: require('assets/images/books/firework.gif'),
    title: "Baby, you're a firework",
    alt: 'Flip book of an inflatable tube man dancing',
    date: '2020',
  },
  {
    imageSrc: require('assets/images/books/nsf.jpg'),
    title:
      'A Facsimile Edition of a Research Paper Presented to the National Science Foundation Young Scholars Program for Which I Was Awarded the Excellence in Research Prize',
    alt: 'NSF research paper, facsimile edition',
    date: '2020',
  },
  {
    imageSrc: require('assets/images/books/bone_ape_tit.jpg'),
    title: 'Bone Ape Tit',
    alt: 'Single sheet zine aoubt food',
    date: '2019',
  },
  {
    imageSrc: require('assets/images/books/fresh_cattitude.jpg'),
    title: 'Fresh Cattitude for Successful Living',
    alt: 'Fresh Cattitude for Successful Living magazine',
    date: '2014',
  },
  {
    imageSrc: require('assets/images/books/niagara_falls.gif'),
    title: 'Yours Till Niagara Falls (postcard)',
    alt: 'Portrait of the artist taken at Niagara Falls',
    date: '2010',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_case.png'),
    title: 'Extreme Violins Box Set',
    alt: 'The Box set collection of Extreme Violins',
    date: '2008 — present',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_12.jpg'),
    title: 'Extreme Violins Vol. 12: Spring Break Forever',
    alt: 'The twelveth issue of Extreme Violins',
    date: '2014',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_11.jpg'),
    title: "Extreme Violins Vol. 11: Le Chat, C'est Moi",
    alt: 'The eleventh issue of Extreme Violins',
    date: '2013',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_10.jpg'),
    title: 'Extreme Violins Vol. 10: Mystic Future America',
    alt: 'The tenth issue of Extreme Violins',
    date: '2012',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_09.jpg'),
    title: 'Extreme Violins Vol. 9: Birthday',
    alt: 'The ninth issue of Extreme Violins',
    date: '2011',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_08.jpg'),
    title: 'Extreme Violins Vol. 8: Christmas',
    alt: 'The eighth issue of Extreme Violins',
    date: '2011',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_07.jpg'),
    title: 'Extreme Violins Vol. 7: California',
    alt: 'The seventh issue of Extreme Violins',
    date: '2010',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_06.jpg'),
    title: 'Extreme Violins Vol. 6: August August',
    alt: 'The sixth issue of Extreme Violins',
    date: '2009',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_05.jpg'),
    title: 'Extreme Violins Vol. 5: Jejune July',
    alt: 'The fifth issue of Extreme Violins',
    date: '2009',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_04.jpg'),
    title: "Extreme Violins Vol. 4: Middlin' March",
    alt: 'The fourth issue of Extreme Violins',
    date: '2009',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_03.jpg'),
    title: 'Extreme Violins Vol. 3: Deathless December',
    alt: 'The third issue of Extreme Violins',
    date: '2008',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_02.jpg'),
    title: 'Extreme Violins Vol. 2: Alliterative October',
    alt: 'The second issue of Extreme Violins',
    date: '2008',
  },
  {
    imageSrc: require('assets/images/books/extreme_violins_01.jpg'),
    title: 'Extreme Violins Vol. 1: Solipsistic September',
    alt: 'The first issue of Extreme Violins',
    date: '2008',
  },
]
