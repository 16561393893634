import LazyImage from 'components/Image/LazyImage'
import Paragraph from 'components/Paragraph/Paragraph'
import { ImageModel } from 'models/ImageModel'
import './StoreItem.scss'

export interface StoreItemProps {
  item: ImageModel
  checkout: React.ReactNode
}

function StoreItem({ item, checkout }: StoreItemProps): JSX.Element {
  return (
    <div className="store-item">
      <LazyImage image={item} showCaption={false} />
      <div>
        <h2 className="store-item-title">
          {item.title}
          <span className="store-item-price">{item.price}</span>
        </h2>
        <div className="store-item-text">
          <Paragraph texts={item.description} />
          {checkout}
        </div>
      </div>
    </div>
  )
}

export default StoreItem
