import { RefObject, useEffect, useState } from 'react'

interface Options {
  threshold?: number
  root?: Element | null
  rootMargin?: string
}

export default function useObserver(
  elementRef: RefObject<Element>,
  { threshold = 1, root = null, rootMargin = '0px' }: Options,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  const options = { threshold, root, rootMargin }

  useEffect(() => {
    const node = elementRef?.current
    if (!node) return
    const observer = new IntersectionObserver(updateEntry, options)
    observer.observe(node)
    return () => observer.disconnect()
  }, [elementRef?.current])

  return entry
}
