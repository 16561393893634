import { Link } from 'react-router-dom'
import './Header.scss'

export interface HeaderProps {
  title?: string
}

function Header({ title = 'Jing Yu Fan Club' }: HeaderProps): JSX.Element {
  return (
    <header className="header">
      <Link to="/">{title}</Link>
    </header>
  )
}

export default Header
