import Paragraph from 'components/Paragraph/Paragraph'
import { intro } from 'constants/intro'
import './About.scss'

function About(): JSX.Element {
  return (
    <section className="about">
      <h2 className="about-title">Introducing Jing Yu Fan Club</h2>
      <div className="about-text">
        <Paragraph texts={intro} />
      </div>
    </section>
  )
}

export default About
