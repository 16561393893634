export const consoleDog = '%cconsole.dog("Still a good boy.")'

export const hangInThere = `
 /$$   /$$                                   /$$          
| $$  | $$                                  |__/          
| $$  | $$  /$$$$$$  /$$$$$$$   /$$$$$$      /$$ /$$$$$$$ 
| $$$$$$$$ |____  $$| $$__  $$ /$$__  $$    | $$| $$__  $$
| $$__  $$  /$$$$$$$| $$  \\ $$| $$  \\ $$    | $$| $$  \\ $$
| $$  | $$ /$$__  $$| $$  | $$| $$  | $$    | $$| $$  | $$
| $$  | $$|  $$$$$$$| $$  | $$|  $$$$$$$    | $$| $$  | $$
|__/  |__/ \\_______/|__/  |__/ \\____  $$    |__/|__/  |__/
   /$$     /$$                 /$$  \\ $$                    
  | $$    | $$                |  $$$$$$/                    
 /$$$$$$  | $$$$$$$   /$$$$$$  \\/$$$$$$  /$$$$$$            
|_  $$_/  | $$__  $$ /$$__  $$ /$$__  $$/$$__  $$           
  | $$    | $$  \\ $$| $$$$$$$$| $$  \\__/ $$$$$$$$           
  | $$ /$$| $$  | $$| $$_____/| $$     | $$_____/           
  |  $$$$/| $$  | $$|  $$$$$$$| $$     |  $$$$$$$/$$        
 /$$___/  |__/  |__//$$______/|__/      \\_______/ $/        
| $$               | $$                         |_/         
| $$$$$$$  /$$$$$$ | $$$$$$$  /$$   /$$                     
| $$__  $$|____  $$| $$__  $$| $$  | $$                     
| $$  \\ $$ /$$$$$$$| $$  \\ $$| $$  | $$                     
| $$  | $$/$$__  $$| $$  | $$| $$  | $$                     
| $$$$$$$/  $$$$$$$| $$$$$$$/|  $$$$$$$ /$$                 
|_______/ \\_______/|_______/  \\____  $$|__/                 
                              /$$  | $$                     
                             |  $$$$$$/                     
                              \\______/                      
`

export const garf = `
                               ...ooooo.         ..---##o
                          .--^""#########o ..o--"  .o#####
        .."-.         ..-^"  .o###########^"    .o########
      ..."^o ^.    .o^"    o##^"#"#"#"#"##   .o#^:^:^:^:#
        "^-:^.# .o#:-    o^"    "-"-"-"-##.o^"-^" " " :# .-^""
           ::#"##o#^.  -""..---------...":^-------. o#^.^.#:"
         -:.-:^:"oo^   .-"             ""o         ^:^#:#"
     ..o:^o:#o:##""  o"                  "o          # "-o.
    o"." ^"-"^-""   #                     #           #  .#.
  .^o"o:-. .        #                    .^           #o-#-#
 .#^.^.^#^#^        #                ...o:      .....o""o^o"#
 #^ "-"- "           #""""^####^""""".-"#####^""""  o"  ^.^.^o
 "#"o"o       .#--""" ^..       ..oo###oo.      ..-"    o:o"o^
  ^-o:o".   .^"          """""""  ::^^^^^::"""""        ^.#-#
    "^o:-:-#   -        ..    .o-"         "--..    .    ^.^
      "-:-:^o            """"""                 """"     o"
         "^-^#.   .o^#...........................    ..-"
              """""   ""                         """"                
`

export const hangInThereFlower = `
.---.  .---.   ____   ,---.   .--. .-_'''-.        .-./'),---.   .--. 
|   |  |_ _| .'  __ '.|    \\  |  |'_( )_   \\       \\ .-.'|    \\  |  | 
|   |  ( ' )/   '  \\  |  ,  \\ |  |(_ o _)|  '      / '-' |  ,  \\ |  | 
|   '-(_{;}_|___|  /  |  |\\_ \\|  . (_,_)/___|       '-''"|  |\\_ \\|  | 
|      (_,_)   _.-'   |  _( )_\\  |  |  .-----.      .---.|  _( )_\\  | 
| _ _--.   |.'   _    | (_ o _)  '  \\  '-   .'      |   || (_ o _)  | 
|( ' ) |   ||  _( )_  |  (_,_)\\  |\\  '-''   |       |   ||  (_,_)\\  | 
(_{;}_)|   |\\ (_ o _) |  |    |  | \\        /       |   ||  |    |  | 
,---------..---._,.---.--' .-''-.'.-------.'      .-''-.''--'    '--' 
\\          |   |  |_ _|  .'_ _   \\|  _ _   \\    .'_ _   \\                
 '--.  ,---|   |  ( ' ) / ( " )   | ( ' )  |   / ( " )   '               
    |   \\  |   '-(_{;}_. (_ o _)  |(_ o _) /  . (_ o _)  |               
    :_ _:  |      (_,_)|  (_,_)___| (_,_).' __|  (_,_)___|               
    (_I_)  | _ _--.   |'  \\   .---|  |\\ \\  |  '  \\   .---._ _            
   (_(=)_) |( ' ) |   | \\  '-'    |  | \\ ''   /\\  '-'    (_I_)           
    (_I_)  (_{;}_)|   |  \\       /|  |  \\    /  \\       (_(=)_)          
 _______'  '(____ '--_______-..-'____'   '__'    ''-..-' (_I_)           
\\  ____  \\ .'  __ '.\\  ____  \\   \\   \\   /  /                            
| |    \\ |/   '  \\  | |    \\ |    \\  _. /  '                             
| |____/ /|___|  /  | |____/ /     _( )_ .'                              
|   _ _ '.   _.-'   |   _ _ '. ___(_ o _)'                               
|  ( ' )  .'   _    |  ( ' )  |   |(_,_)_ _                              
| (_{;}_) |  _( )_  | (_{;}_) |   '-'  ( " )                             
|  (_,_)  \\ (_ o _) |  (_,_)  /\\      (_{;}_)                            
/_______.' '.(_,_).'/_______.'  '-..-' (_,_)                             
`
