import CheckoutButton from 'components/CheckoutButton/CheckoutButton'
import StoreItem from './StoreItem'
import { store } from 'constants/store'
import './Store.scss'
export interface StoreProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripePromise: any
}

function Store({ stripePromise }: StoreProps): JSX.Element {
  return (
    <section className="store">
      <StoreItem
        item={store[0]}
        checkout={
          <CheckoutButton stripePromise={stripePromise} priceId={store[0].priceId} shippingId={store[0].shippingId} />
        }
      />
      <StoreItem
        item={store[1]}
        checkout={
          <a
            href="https://www.zazzle.com/classic_coffee_mug-168556557994896969"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy it on Zazzle
          </a>
        }
      />
      <StoreItem
        item={store[2]}
        checkout={
          <a href={require('assets/books/firework_print.pdf')} target="_blank">
            Download free PDF
          </a>
        }
      />
    </section>
  )
}

export default Store
