import LazyImage from 'components/Image/LazyImage'
import Paragraph from 'components/Paragraph/Paragraph'
import { ImageModel } from 'models/ImageModel'
import './Gallery.scss'

export interface GalleryProps {
  checklist: Array<ImageModel>
  text?: Array<string>
}

function Gallery({ checklist, text }: GalleryProps): JSX.Element {
  return (
    <section className="gallery">
      {text && <Paragraph texts={text} />}
      {checklist.map((image) => (
        <LazyImage image={image} key={image.alt} showCaption={true} />
      ))}
    </section>
  )
}

export default Gallery
