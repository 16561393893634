import LazyImage from 'components/Image/LazyImage'
import Paragraph from 'components/Paragraph/Paragraph'
import Video from 'components/Video/Video'
import * as checklist from 'constants/pinatas'
import './Pinatas.scss'

function Pinatas(): JSX.Element {
  return (
    <section className="pinatas">
      <div className="pinata-intro">
        <Paragraph texts={checklist.text} />
      </div>
      <Video
        source="https://player.vimeo.com/video/12303886"
        title="Let's Excercise!"
        loop={true}
        autoplay={true}
        muted={true}
      />
      <div className="pinata-press">
        <p>As Seen In: </p>
        {checklist.press.map((article) => (
          <a
            href={article.link}
            target="_blank"
            rel="noopener noreferrer"
            className="pinata-press-item"
            key={article.link}
          >
            {`${article.publication}\u2002\u2020\u2002`}
          </a>
        ))}
      </div>
      <div className="pinata-gallery">
        {checklist.pinatas.map((pinata) => (
          <LazyImage image={pinata} key={pinata.alt} showCaption={true} />
        ))}
      </div>
    </section>
  )
}

export default Pinatas
