import tears from 'assets/common/404.gif'
import './NotFound.scss'

export interface NotFoundProps {
  errorText?: string
}

function NotFound({ errorText = 'Page Not Found' }: NotFoundProps): JSX.Element {
  return (
    <section className="not-found">
      <h1>{errorText}</h1>
      <img src={tears} alt="a schnoodle is crying because the page was not found" />
    </section>
  )
}

export default NotFound
