export const sculptures = [
  {
    imageSrc: require('assets/images/sculpture/signifying_nothing.jpg'),
    title: 'SIGNIFYING NOTHING',
    alt: 'Knit baby blanket in black wool with the Monster Energy logo in neon green',
    date: '2021',
    medium: 'Merino wool',
    size: '42 x 42 x 0.25 in.',
    notes: 'Edition 8',
  },
  {
    imageSrc: require('assets/images/sculpture/self_portrait_with_peonies.jpg'),
    title: 'Self Portrait with Peonies',
    alt: "Pleated cloth facemask with a print of the artist's face",
    date: '2020',
    medium: 'Inkjet print on fabric, elastic',
    size: '5.5 x 10 x 3 in.',
    notes: 'Edition 8',
  },
  {
    imageSrc: require('assets/images/sculpture/moon_jar.jpg'),
    title: 'Moon Jar',
    alt: 'Portrait of the artist on a moon jar and a drawing of the moon jar',
    date: '2020',
    medium: 'Porcelain, glaze / watercolor on paper',
    size: '7.5 x 7.5 x 7.5 in. / 15 x 11 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/sculpture/i_hate_mondaze.jpg'),
    title: 'i hate mondaze.',
    alt: "Porcelain planter in the shape of Garfield's head",
    date: '2022',
    medium: 'Porcelain, glaze, Dioscorea elephantipes',
    size: '5.75 x 5 x 5 in.',
    notes: 'Open edition',
  },
  {
    imageSrc: require('assets/images/sculpture/put_some_makeup_on _your_face.jpg'),
    title: 'Put some makeup on your face/Make this world a better place',
    alt: 'Brass and glass mirror curio cabinet displaying rows of porcelain lipsticks in a variety of warm shades',
    date: '2020',
    medium: 'Porcelain, glaze, glass, brass',
    size: '23 x 12 x 4.5 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/sculpture/all_art_is.jpg'),
    title: 'All art is at once surface and symbol and the ecstasy is kicking in (baby blanket)',
    alt: 'Neon yellow knit baby blanket with pastel pot leaf eyes and a cartoon smile',
    date: '2018',
    medium: 'Merino wool',
    size: '32 x 32 x 0.25 in.',
    notes: 'Edition 8',
  },
  {
    imageSrc: require('assets/images/sculpture/do_the_dew.jpg'),
    title: 'Do the Dew',
    alt: 'Porcelain mug with white gold luster that looks like a Monster Energy drink can',
    date: '2019',
    medium: 'Porcelain, glaze, white gold luster',
    size: '5.5 x 3 x 3 in.',
    notes: 'Open edition',
  },
  {
    imageSrc: require('assets/images/sculpture/daddy.jpg'),
    title: 'My heart belongs to',
    alt: 'Porcelain ring that spells the word daddy in gold luster in a clear acrylic case',
    date: '2019',
    medium: 'Porcelain, glaze, gold luster, acrylic',
    size: '4 x 4 x 2 in.',
    notes: 'Open edition',
  },
  {
    imageSrc: require('assets/images/sculpture/cool_ranch_and_butt.jpg'),
    title: 'Cool Ranch and Butt',
    alt: 'A cigarette butt perched on a pile of Cool Ranch Doritos in a bowl',
    date: '2017',
    medium: 'Porcelain, glaze',
    size: '3.25 x 7 x 7 in.',
    notes: '',
  },
  {
    imageSrc: require('assets/images/sculpture/banana_phone.jpg'),
    title: 'Banana Phone',
    alt: 'Headset with a microphone in the shape of a banana',
    date: '2012',
    medium: 'Headset, wire, papier-mâché',
    size: '6 x 7.5 x 4 in.',
    notes: 'Edition 6',
  },
  {
    imageSrc: require('assets/images/sculpture/orange_you_glad.jpg'),
    title: "Orange you glad I didn't say banana?",
    alt: 'Very orange banana peel cast with a blend of cheeses',
    date: '2012',
    medium: 'Cheddar, Velveeta',
    size: '8 x 10 x 6 in.',
    notes: 'Open edition',
  },
  {
    imageSrc: require('assets/images/sculpture/for_the_love_of_damien_wurst.jpg'),
    title: 'For the Love of Damien Wurst',
    alt: 'Plastic skull covered in googly eyes with gold teeth',
    date: '2011',
    medium: 'Plastic skull, googly eyes, 24k gold leaf',
    size: '6 x 6 x 7 in.',
    notes: '',
  },
]
